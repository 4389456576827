import classNames from 'classnames';
import { ArrowRight } from './Icons';

const Button = (props) => {
  let buttonClass = classNames(
    props.className,
    'btn disabled:opacity-25 disabled:italic'
  );

  return (
    <button
      className={buttonClass}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <span>{props.children}</span>
      <ArrowRight className="absolute w-4 h-4 -mt-2 right-3 top-1/2" />
    </button>
  );
};

export default Button;
