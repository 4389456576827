import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Router from 'next/router';

import Error from '../components/ErrorMessage';
import CURRENT_USER_QUERY from '../lib/currentUserQuery';
import Container from './styles/Container';
import Button from './styles/Button';
import { Logo } from './styles/Icons';
import { useRouter } from 'next/router';
import User from './wrappers/User';

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      email
      belongsToCompany {
        companyName
      }
    }
  }
`;

let SignInWrapped = () => {
  let [email, updateEmail] = useState('');
  let [password, updatePassword] = useState('');

  let handleSubmit = async (signin) => {
    const res = await signin();
  };

  return (
    <>
      <div className="relative z-10 flex flex-col items-center sm:mb-8">
        <Logo
          keyName={'bottomlogotwo'}
          className="hidden w-48 h-auto mx-auto mt-3 overflow-hidden sm:inline-block"
        />
        <div className="mt-2 text-center">
          <p className="hidden mx-auto text-4xl font-black leading-none uppercase sm:block">
            Phoenix
          </p>
          <p className="hidden mx-auto text-2xl font-black leading-none uppercase sm:block">
            DryLINK Subscriptions
          </p>
        </div>
      </div>
      <Container className="relative z-10 w-full mx-auto ">
        <div className="flex flex-col justify-between w-full h-full px-6 pt-8 pb-8 mx-auto bg-gray-100 sm:h-auto lg:w-1/3 sm:rounded sm:py-16 sm:px-12">
          <Mutation
            mutation={SIGNIN_MUTATION}
            variables={{ email, password }}
            refetchQueries={[{ query: CURRENT_USER_QUERY }]}
          >
            {(signin, { error, loading }) => {
              return (
                <form
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(signin);
                  }}
                >
                  <div>
                    <div>
                      <fieldset
                        className="flex flex-col"
                        disabled={loading}
                        aria-busy={loading}
                      >
                        <Error error={error} />
                        <label className="text-gray-500" htmlFor="email">
                          Email address
                        </label>
                        <div className="mb-8 input-wrapper">
                          <div className="input">
                            <input
                              type="text"
                              className="w-full px-4 py-3 rounded"
                              name="email"
                              value={email}
                              onChange={(e) => updateEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <label className="text-gray-500" htmlFor="password">
                          Password
                        </label>
                        <div className="mb-16 input-wrapper">
                          <div className="input">
                            <input
                              type="password"
                              className="w-full px-4 py-3 rounded"
                              name="password"
                              value={password}
                              onChange={(e) => updatePassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <Button type="submit">Sign In</Button>
                        <div className="mt-4 text-center">
                          <p className="text-sm text-gray-500">
                            Forgot your password? Reset within the DryLINK App.
                          </p>
                          <p className="text-sm text-gray-500">
                            Need an account?{' '}
                            <a
                              className="underline"
                              href="https://usephoenix.com/drylink/ecosystem/"
                            >
                              Download the DryLINK App.
                            </a>
                          </p>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </form>
              );
            }}
          </Mutation>
          <Logo
            keyName={'bottomlogoone'}
            className="w-full h-auto mt-24 opacity-25 sm:hidden"
          />
        </div>{' '}
      </Container>

      <div className="absolute inset-0 z-0 hidden bg-center bg-cover bg-phoenix-gray-dark sm:block" />
    </>
  );
};

let SignIn = () => {
  let router = useRouter();
  return <SignInWrapped router={router} />;
};

export default SignIn;
export { SIGNIN_MUTATION };
