import Signin from '../components/Signin';
import { CURRENT_USER_QUERY } from '../components/wrappers/User';
import Router from 'next/router';
import cookies from 'next-cookies';

const Home = (props) => <Signin />;

// Home.getInitialProps = async (ctx) => {
//   let path = '/reports';

//   let { res } = ctx;
//   let { token } = cookies(ctx);
//   console.log(token);
//   if (token) {
//     if (typeof window === 'undefined') {
//       res.writeHead(302, { Location: path });
//       res.end();
//       return {};
//     }
//     Router.push(path);
//   }
//   return {};
// };

export default Home;
